import { useCallback } from 'react'

import type { DailyReportsScheduleType, DailyReportsWorkspaceType } from 'api/daily_reports/types'

import { ScheduleTypeEditor } from './ScheduleTypeEditor'

import styles from './ScheduleTypeMapping.module.scss'

type ScheduleTypeMappingProps = {
  workspace: DailyReportsWorkspaceType
  onChange: (value: DailyReportsScheduleType[]) => void
}

export type WorkspaceCheckBoxItemTypes = {
  id: number
  label: string
  checked: boolean
  disabled: boolean
}

export const ScheduleTypeMapping = ({ workspace, onChange }: ScheduleTypeMappingProps) => {
  // プログレスバーの計算
  const calculateProgress = useCallback(() => {
    const fullFilledScheduleTypes = workspace.scheduleTypes.filter(
      type => type.header.length > 0 && type.header.every(header => header !== '')
    ).length

    const totalScheduleTypes = workspace.scheduleTypes.length

    return totalScheduleTypes > 0 ? (fullFilledScheduleTypes / totalScheduleTypes) * 100 : 0
  }, [workspace.scheduleTypes])

  // 編集処理
  const handleHeaderChange = useCallback(
    (scheduleTypeId: number, headerIndex: number, newValue: string) => {
      const scheduleTypes = workspace.scheduleTypes.map(type => {
        if (type.id !== scheduleTypeId) {
          return type
        }

        // headerが空配列の場合は新規追加
        if (type.header.length === 0) {
          return {
            ...type,
            header: [newValue],
          }
        }

        return {
          ...type,
          header: type.header.map((header, index) => (index === headerIndex ? newValue : header)),
        }
      })

      onChange(scheduleTypes)
    },
    [onChange, workspace]
  )

  // 追加処理
  const handleHeaderAdd = useCallback(
    (scheduleTypeId: number) => {
      const scheduleTypes = workspace.scheduleTypes.map(type => {
        if (type.id !== scheduleTypeId) {
          return type
        }

        return {
          ...type,
          header: type.header.concat(''),
        }
      })

      onChange(scheduleTypes)
    },
    [onChange, workspace]
  )

  // 削除処理
  const handleHeaderRemove = useCallback(
    (scheduleTypeId: number, headerIndex: number) => {
      const scheduleTypes = workspace.scheduleTypes.map(type => {
        if (type.id !== scheduleTypeId) {
          return type
        }

        return {
          ...type,
          header: type.header.filter((_, index) => index !== headerIndex),
        }
      })

      onChange(scheduleTypes)
    },
    [onChange, workspace]
  )

  return (
    <>
      <div className="mb-3">作業マッピング設定</div>
      <div className="mb-2">このワークスペースのマッピング達成度</div>
      <div className={`${styles.progressContainer} mb-4`}>
        <div
          className={styles.progressBar}
          style={{
            width: `${calculateProgress()}%`,
            backgroundColor: calculateProgress() === 100 ? 'rgb(var(--bs-success-rgb))' : 'rgb(var(--bs-danger-rgb))',
          }}
        />
      </div>
      <div className={styles.scheduleTypeContainer}>
        {workspace.scheduleTypes.map((type, index) => (
          <div className={index === workspace.scheduleTypes.length - 1 ? '' : 'mb-3'} key={`${type.id}-${index}`}>
            <ScheduleTypeEditor
              scheduleType={type}
              onChange={(headerIndex, newValue) => handleHeaderChange(type.id, headerIndex, newValue)}
              onAddButtonClick={() => handleHeaderAdd(type.id)}
              onRemoveButtonClick={headerIndex => handleHeaderRemove(type.id, headerIndex)}
            />
          </div>
        ))}
      </div>
    </>
  )
}
