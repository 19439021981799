import type { DailyReportsScheduleType } from 'api/daily_reports/types'

import { IconButton } from 'components/common'
import { COLUMN_SIZES } from 'components/common/constants'

import { InputFormatWithArrow } from './FormInputWithArrow'
import { MAX_LENGTH } from './constants'

import styles from './ScheduleTypeEditor.module.scss'

type ScheduleTypeEditorProps = {
  scheduleType: DailyReportsScheduleType
  onChange: (headerIndex: number, newValue: string) => void
  onAddButtonClick: () => void
  onRemoveButtonClick: (headerIndex: number) => void
}

export const ScheduleTypeEditor = (props: ScheduleTypeEditorProps) => {
  const { scheduleType, onChange, onAddButtonClick, onRemoveButtonClick } = props

  // ヘッダーが空の場合はデフォルトで空の入力フィールドを表示
  const headers = scheduleType.header.length > 0 ? scheduleType.header : ['']

  // すべてのヘッダーが入力済みかをチェック
  const isAllHeadersFilled = headers.every(header => header.trim() !== '')

  return (
    <>
      {headers.map((value, index) => (
        <div
          key={`${scheduleType.id}-${index}`}
          className="d-flex justify-content-between align-items-center mb-2 gap-2"
        >
          {/* 作業名とアイコンのコンテナ */}
          <div className={`d-flex align-items-center flex-grow-1 flex-shrink-0 ${styles.scheduleTypeNameContainer}`}>
            {index === 0 && (
              <>
                <i
                  className={`font-large ${isAllHeadersFilled ? 'icf-check text-success' : 'icf-alert text-warning'}`}
                />
                <div className="ms-2 text-truncate" title={scheduleType.name}>
                  {scheduleType.name}
                </div>
              </>
            )}
          </div>
          {/* テキストボックス（テキストボックスにはラベルを表示しない） */}
          <InputFormatWithArrow
            label=""
            value={value}
            size={COLUMN_SIZES.FULL}
            maxLength={MAX_LENGTH}
            onChange={newValue => onChange(index, newValue)}
            placeholder="文字列を入力"
          />
          {/* ボタン用の固定幅コンテナ */}
          <div className={`d-flex align-items-center ${styles.buttonContainer}`}>
            {value.trim() !== '' &&
              index === headers.length - 1 && ( // 入力がある場合のみ追加ボタンを表示
                <IconButton
                  outline
                  icon="plus"
                  size="sm"
                  className="me-1"
                  onClick={onAddButtonClick}
                  tooltipText="追加"
                  name="schedule-header-add-icon-button"
                />
              )}
            {headers.length > 1 && ( // 複数のフィールドがある場合のみ削除ボタンを表示
              <IconButton
                outline
                icon="delete"
                size="sm"
                onClick={() => onRemoveButtonClick(index)}
                tooltipText="削除"
                name="schedule-header-delete-icon-button"
                iconColor="text-danger"
              />
            )}
          </div>
        </div>
      ))}
    </>
  )
}
