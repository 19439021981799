import { useState, useCallback, useEffect, useMemo } from 'react'
import { shallowEqual, useSelector, useDispatch } from 'react-redux'
import { Card, CardBody, CardTitle, Button, Label, Row, Input } from 'reactstrap'

import type { DailyReportsWorkspaceType, EditDailyReportsImportSettingsProps } from 'api/daily_reports/types'

import { selectDailyReportsStatus } from 'slices/dailyReportsSlice'
import { selectScheduleTypesStatus, getScheduleTypeList } from 'slices/scheduleTypesSlice'
import { selectWorkspacesStatus, getWorkspaceList } from 'slices/workspacesSlice'

import { InputFormat } from 'components/common'
import type { CharacterCodeType } from 'components/common/CharacterCodeSelect/CharacterCodeSelect'
import { CharacterCodeSelect } from 'components/common/CharacterCodeSelect/CharacterCodeSelect'
import * as Rules from 'components/common/FormFormat/ValidationRules'
import { COLUMN_SIZES } from 'components/common/constants'

import { InputFormatWithArrow } from './FormInputWithArrow'
import { WorkspaceTabs } from './WorkspaceTabs'
import { MAX_LENGTH } from './constants'

import type { WorkspaceCheckBoxItemTypes } from './ScheduleTypeMapping'

type Props = {
  editData: EditDailyReportsImportSettingsProps
  onChange: <K extends keyof EditDailyReportsImportSettingsProps>(
    key: K,
    value: EditDailyReportsImportSettingsProps[K]
  ) => void
  onDeleteButtonClick: () => void
  disabledDeleteButton: boolean
  onValidate: (disabled: boolean) => void
}

export const DailyReportSettingsDetail = (props: Props) => {
  const { editData, onChange, onDeleteButtonClick, disabledDeleteButton, onValidate } = props
  const [nameValidity, setNameValidity] = useState(false)
  const [workDateValidity, setWorkDateValidity] = useState(false)
  const [scheduleTypeNameValidity, setScheduleTypeNameValidity] = useState(false)
  const [wmsMemberIdValidity, setWmsMemberIdValidity] = useState(false)
  const [startTimeValidity, setStartTimeValidity] = useState(false)
  const [endTimeValidity, setEndTimeValidity] = useState(false)
  const [workspaceCheckBoxItems, setWorkspaceCheckBoxItems] = useState<WorkspaceCheckBoxItemTypes[]>([])

  const { dailyReportsImportSettingsList } = useSelector(selectDailyReportsStatus, shallowEqual)
  const { partialWorkspaces } = useSelector(selectWorkspacesStatus, shallowEqual)
  const { partialScheduleTypes } = useSelector(selectScheduleTypesStatus, shallowEqual)

  const dispatch = useDispatch()

  useEffect(() => {
    dispatch(getWorkspaceList())
  }, [dispatch])

  useEffect(() => {
    const areAllFieldsValid =
      nameValidity &&
      workDateValidity &&
      scheduleTypeNameValidity &&
      wmsMemberIdValidity &&
      startTimeValidity &&
      endTimeValidity
    onValidate(!areAllFieldsValid)
  }, [
    nameValidity,
    workDateValidity,
    scheduleTypeNameValidity,
    wmsMemberIdValidity,
    startTimeValidity,
    endTimeValidity,
    onValidate,
  ])

  // ワークスペース一覧から、workspaceCheckBoxItemsを作成
  useEffect(() => {
    if (partialWorkspaces.length === 0) {
      return
    }

    const newWorkspaceItems = partialWorkspaces.map(ws => {
      const checked = editData.workspaces?.some(editWs => editWs.id === ws.id) || false
      const usedOtherWorkspace =
        dailyReportsImportSettingsList?.some(report => report.workspaceIds.includes(ws.id)) || false

      return {
        id: ws.id,
        label: ws.name,
        checked,
        disabled: usedOtherWorkspace,
      }
    })

    setWorkspaceCheckBoxItems(newWorkspaceItems)
  }, [partialWorkspaces, dailyReportsImportSettingsList, editData.workspaces])

  // 作業一覧を取得して、editDataにscheduleTypesが存在しない場合、そのワークスペースの作業を追加する
  useEffect(() => {
    if (partialScheduleTypes.length === 0) {
      return
    }

    const targetWorkspaceId = partialScheduleTypes[0].workspaceId
    const targetWorkspace = editData.workspaces.find(ws => ws.id === targetWorkspaceId)

    // editDataにworkspaceが存在しない、またはscheduleTypesが既に存在する場合は何もしない
    if (!targetWorkspace || targetWorkspace.scheduleTypes.length > 0) {
      return
    }

    const scheduleTypes = partialScheduleTypes.map(scheduleType => ({
      id: scheduleType.id,
      name: scheduleType.name,
      header: [],
    }))

    const workspaces = {
      id: targetWorkspaceId,
      name: partialWorkspaces.find(ws => ws.id === targetWorkspaceId)?.name || '',
      scheduleTypes,
    }

    const updatedWorkspaces = editData.workspaces.map(ws => (ws.id === workspaces.id ? workspaces : ws))

    onChange('workspaces', updatedWorkspaces)
  }, [partialScheduleTypes, editData, onChange, partialWorkspaces])

  // ワークスペースをチェックした時の処理
  const handleWorkspaceCheckboxChange = useCallback(
    (id: number, checked: boolean) => {
      // チェックONの場合、editData.workspacesに該当ワークスペースを追加
      if (checked) {
        const workspaces = {
          id: id,
          name: partialWorkspaces.find(ws => ws.id === id)?.name || '',
          scheduleTypes: [],
        }

        onChange('workspaces', [...editData.workspaces, workspaces])
        dispatch(getScheduleTypeList(id))
      } else {
        // チェックOFFの場合、editData.workspacesから該当ワークスペースを削除
        onChange(
          'workspaces',
          editData.workspaces.filter(ws => ws.id !== id)
        )
      }

      // workspaceCheckBoxItemsのcheckedを更新
      // （チェックOFFの場合、最初のタブをアクティブにする。）
      setWorkspaceCheckBoxItems(prev => {
        const updatedItems = prev.map(item => (item.id === id ? { ...item, checked } : item))

        return updatedItems
      })
    },
    [dispatch, editData, onChange, partialWorkspaces]
  )

  const handleTabChange = useCallback(
    (newWorkspaces: DailyReportsWorkspaceType[]) => {
      onChange('workspaces', newWorkspaces)
    },
    [onChange]
  )

  // チェックされたワークスペースを取得
  const tabItems = useMemo(() => workspaceCheckBoxItems.filter(item => item.checked), [workspaceCheckBoxItems])

  const handleCharacterCodeChange = (value: CharacterCodeType) => {
    onChange('encoding', value)
  }

  return (
    <div className="h-100 overflow-auto p-3">
      <div className="mb-4">
        <div className="d-flex justify-content-between">
          <div className="font-large fw-bold mb-2">日報実績インポート設定</div>
          <span className="font-x-small text-muted">※必須項目</span>
        </div>
        <InputFormat
          label="名称※"
          placeholder="名称を入力"
          value={editData.name}
          size={COLUMN_SIZES.MIDDLE}
          maxLength={MAX_LENGTH}
          onChange={value => onChange('name', value)}
          validations={[Rules.Required]}
          className="mb-3"
          onValidate={setNameValidity}
        />
        <div className="mb-3">
          <CharacterCodeSelect characterCode={editData.encoding} onChange={handleCharacterCodeChange} />
        </div>
      </div>
      <div className="mb-4">
        <div className="font-large fw-bold mb-2">マッピング設定</div>
        <div className="mb-3">
          スマイルボードコネクト内の項目と日報実績CSVのヘッダーとのマッピング設定ができます。下記の項目に対応する日報実績CSVのヘッダー名を入力してください。
        </div>
        <Card>
          <CardBody>
            <CardTitle>マッピング設定</CardTitle>
            <InputFormatWithArrow
              label="営業日※"
              placeholder="営業日を入力"
              value={editData.settings?.workDate || ''}
              size={COLUMN_SIZES.MIDDLE}
              maxLength={MAX_LENGTH}
              onChange={value => onChange('settings', { ...editData.settings, workDate: value })}
              validations={[Rules.Required]}
              className="mb-3"
              onValidate={setWorkDateValidity}
            />
            <InputFormatWithArrow
              label="作業※"
              placeholder="作業を入力"
              value={editData.settings?.scheduleType}
              size={COLUMN_SIZES.MIDDLE}
              maxLength={MAX_LENGTH}
              onChange={value => onChange('settings', { ...editData.settings, scheduleType: value })}
              validations={[Rules.Required]}
              className="mb-3"
              onValidate={setScheduleTypeNameValidity}
            />
            <InputFormatWithArrow
              label="識別子※"
              placeholder="識別子を入力"
              value={editData.settings?.wmsMemberId}
              size={COLUMN_SIZES.MIDDLE}
              maxLength={MAX_LENGTH}
              onChange={value => onChange('settings', { ...editData.settings, wmsMemberId: value })}
              validations={[Rules.Required]}
              className="mb-3"
              onValidate={setWmsMemberIdValidity}
            />
            <InputFormatWithArrow
              label="開始時間※"
              placeholder="開始時間を入力"
              value={editData.settings?.startTime}
              size={COLUMN_SIZES.MIDDLE}
              maxLength={MAX_LENGTH}
              onChange={value => onChange('settings', { ...editData.settings, startTime: value })}
              validations={[Rules.Required]}
              className="mb-3"
              onValidate={setStartTimeValidity}
            />
            <InputFormatWithArrow
              label="終了時間※"
              placeholder="終了時間を入力"
              value={editData.settings?.endTime}
              size={COLUMN_SIZES.MIDDLE}
              maxLength={MAX_LENGTH}
              onChange={value => onChange('settings', { ...editData.settings, endTime: value })}
              validations={[Rules.Required]}
              className="mb-3"
              onValidate={setEndTimeValidity}
            />
          </CardBody>
        </Card>
      </div>
      <div className="mb-4">
        <CardTitle className="font-large fw-bold">作業マッピング設定</CardTitle>
        <div className="mb-3">
          スマイルボードコネクト内の作業と日報実績CSVの作業を記録する列の作業を特定する文字列とのマッピング設定ができます。
        </div>
        <Card>
          <CardBody>
            <div className="mb-1">この日報実績インポート設定で日報実績を取り込むワークスペースの選択</div>
            <div className="text-danger mb-3">
              他の日報実績インポート設定で選択されているワークスペースは選択できません。
            </div>
            <Row md={2} className="ms-1">
              {workspaceCheckBoxItems.map(item => (
                <div key={item.id} className="form-check">
                  <Input
                    className="form-check-input"
                    id={`workspace_${item.id}_checkBox`}
                    checked={item.checked}
                    type="checkbox"
                    disabled={item.disabled}
                    onChange={e => handleWorkspaceCheckboxChange(item.id, e.target.checked)}
                  />
                  <Label className="form-check-label" for={`workspace_${item.id}_checkBox`}>
                    {item.label}
                  </Label>
                </div>
              ))}
            </Row>
          </CardBody>
        </Card>
        {tabItems.length > 0 && (
          <>
            <div className="mt-3 mb-1">下記の作業に対応する日報実績CSVの作業を特定する文字列を入力してください。</div>
            <div className="text-danger mb-3">
              {/* TODO 詳細ページのリンクURLを修正 */}
              ワークスペース内で全ての作業でマッピングが完了しないと、日報実績としてデータは取り込めません。詳しくは
              <a href="#">こちら</a>をご確認ください。
            </div>
            <Card>
              <WorkspaceTabs workspaces={editData.workspaces} onChange={handleTabChange} />
            </Card>
          </>
        )}
      </div>
      <div className="font-large fw-bold">日報実績インポート設定の削除</div>
      <Button outline color="danger" className="my-3" onClick={onDeleteButtonClick} disabled={disabledDeleteButton}>
        この日報実績インポート設定を削除
      </Button>
    </div>
  )
}
