import { useState, useMemo, useEffect, useCallback } from 'react'

import type { DailyReportsScheduleType, DailyReportsWorkspaceType } from 'api/daily_reports/types'

import { PivotItem, PivotOuterIndex } from 'components/common'

import { ScheduleTypeMapping } from './ScheduleTypeMapping'

import styles from './WorkspaceTabs.module.scss'

type WorkspaceTabsProps = {
  workspaces: DailyReportsWorkspaceType[]
  onChange: (id: DailyReportsWorkspaceType[]) => void
}

type WorkspaceId = number

export const WorkspaceTabs = ({ onChange, workspaces }: WorkspaceTabsProps) => {
  const [activeTabId, setActiveTabId] = useState<WorkspaceId | null>(null)

  useEffect(() => {
    setActiveTabId(prev => {
      if (prev === null && workspaces.length > 0) {
        return workspaces[0].id
      }
      // アクティブなworkspaceのチェックを外された場合、0番目をアクティブにする
      if (workspaces.some(ws => ws.id === prev)) {
        return prev
      }
      if (workspaces.length > 0) {
        return workspaces[0].id
      }
      return null
    })
  }, [workspaces])

  const tabs = useMemo(
    () =>
      workspaces.map(ws => {
        const isActive = ws.id === activeTabId
        const hasUnfilledSchedules = ws.scheduleTypes.some(
          type => type.header.length === 0 || type.header.some(header => header.trim() === '')
        )

        const handleScheduleTypeChange = (scheduleTypes: DailyReportsScheduleType[]) => {
          const newWorkspaces = workspaces.map(workspace =>
            workspace.id === ws.id ? { ...workspace, scheduleTypes } : workspace
          )
          onChange(newWorkspaces)
        }

        return {
          headerText: ws.name,
          key: ws.id,
          component: <ScheduleTypeMapping workspace={ws} onChange={handleScheduleTypeChange} />,
          icon: hasUnfilledSchedules ? 'alert' : '',
          iconColor: isActive ? 'text-warning' : '',
        }
      }),
    [activeTabId, onChange, workspaces]
  )

  const handleTabChange = useCallback(
    (index: number) => {
      setActiveTabId(workspaces[index].id)
    },
    [workspaces]
  )

  return activeTabId === null ? (
    <></>
  ) : (
    <div className={`pt-3 mx-3 overflow-auto text-nowrap ${styles.noScrollbar}`}>
      <PivotOuterIndex selectedIndex={workspaces.findIndex(w => w.id === activeTabId)} onChange={handleTabChange}>
        {tabs.map(tab => (
          <PivotItem headerText={tab.headerText} key={tab.key} icon={tab.icon} iconColor={tab.iconColor}>
            {tab.component}
          </PivotItem>
        ))}
      </PivotOuterIndex>
    </div>
  )
}
